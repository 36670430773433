/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://yh6glc3yyrdv7jgqpu5buwcpdq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hw2tb42k4rajnhps23jv5d3fka",
    "aws_cloud_logic_custom": [
        {
            "name": "fortknoxrestapi",
            "endpoint": "https://88farqqqyb.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:2b1ad91f-8add-470c-8c6d-6cb8bd6fe5b7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_VfQVnh7Bk",
    "aws_user_pools_web_client_id": "msa7qtv06lladaca1t5bumske",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "fortknoxfileuploadbucketb0ab2-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
